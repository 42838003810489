
import { defineComponent, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import { formatNumber } from '@/services/utils'

export default defineComponent({
  name: 'Tables',
  components: {
    TmBadge,
    TmDropdownItem,
    TmTableActionButton,
    TmTable,
    TmStatus,
    TmDropdown,
  },
  setup() {
    const showSelection = ref(true)
    const scroll = ref(false)
    const draggable = ref(false)
    const selected = ref([])
    const columns = ref<any[]>([
      { text: 'List Name', value: 'list-name' },
      { text: 'Contacts', value: 'contacts' },
      { text: 'Type', value: 'type' },
      { text: 'Date Created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])

    const rows = ref<any[]>(getTableData('contactsLists'))

    return {
      showSelection,
      scroll,
      draggable,
      selected,
      columns,
      rows,
      formatNumber,
    }
  },
})
